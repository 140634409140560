import { ID } from '@/data/types/Basic';
import { FC, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useESpotDataFromName } from '@/data/Content/_ESpotDataFromName';
import { carouselSlideTestimonialSX } from '@/components/content/CustomEspot/styles/carouselSliderTestimonial';
import { CardContent, Typography, Card, Box, Grid } from '@mui/material';
import { CustomProgressIndicator } from '@/components/blocks/CustomProgressIndicator';
import { StaticSlider } from '@/components/blocks/Carousel/StaticSlider';
import { CarouselProvider } from '@/components/blocks/Carousel/CarouselProvider';
import { carouselProviderSX, dotContainerSX } from '@/components/blocks/FeaturedProductCarousel/styles/carouselProvider';
import { ButtonBack, ButtonNext, Dot, Slider } from 'pure-react-carousel';
import { CarouselSlide } from '@/components/blocks/Carousel/CarouselSlide';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { parseHTML } from '@/utils/parseHTML';

type PropertiesType = {
    configInput: string;
    emsName: string;
    emsType: string;
    titleInput: string
};

type SliderDataType = {
    [key: string]: any;
}

export const CustomEspot: FC<{
    id: ID;
    properties?: PropertiesType;
}> = ({ id, properties }) => {
    const [isLoading] = useState(properties?.emsName ? true : false);
    const sliderConfig = eval('(' + properties?.configInput + ')');
    const sm = sliderConfig?.sm ? sliderConfig?.sm : 1;
    const md = sliderConfig?.md ? sliderConfig?.md : 2;
    const lg = sliderConfig?.lg ? sliderConfig?.lg : 3;
    const { data, loading } = useESpotDataFromName(properties?.emsName ? properties?.emsName : '');
    const SliderData: SliderDataType[] = [];
    data?.MarketingSpotData?.[0]?.baseMarketingSpotActivityData?.map((item) => {
        if (item?.marketingContentDescription?.[0]) {
            SliderData.push(item?.marketingContentDescription?.[0]);
        }
    });

    const slides = useMemo(
        () =>
            (SliderData || []).map((item: any, index: number) => (
                <Card key={index} className='testimonialContainer'>
                    <CardContent sx={{ padding: '24px', minHeight: '260px' }}>
                        <Box>
                            {parseHTML(item?.marketingText)}
                        </Box>
                    </CardContent>
                </Card>
            )),
        [SliderData]
    );

    const theme = useTheme();
    const lgMatch = useMediaQuery(theme.breakpoints.down('lg'));
    const mdMatch = useMediaQuery(theme.breakpoints.down('md'));
    const smMatch = useMediaQuery(theme.breakpoints.down('sm'));
    const visibleSlides = useMemo(
        () => (smMatch ? sm : mdMatch ? md : lgMatch ? lg : lg),
        [lgMatch, mdMatch, smMatch]
    );
    const disabledSliding = useMemo(() => visibleSlides >= slides.length, [visibleSlides, slides]);

    const defaultCarouselProps = {
        naturalSlideWidth: 248,
        naturalSlideHeight: 300,
        visibleSlides,
        step: visibleSlides,
        infinite: true,
        dragEnabled: false,
        totalSlides: 1,
        isIntrinsicHeight: true,
    };


    return (
        <>
            {isLoading ? (
                <>
                    {loading ? (
                        <CustomProgressIndicator />
                    ) : disabledSliding ? (
                        <>
                            <Typography variant={'h2'} align='center' pt={6} pb={6} >{properties?.titleInput}</Typography>
                            <StaticSlider>{slides}</StaticSlider>
                        </>
                    ) : (
                        <>
                            <Typography variant={'h2'} align='center' pt={6} pb={6} >{properties?.titleInput}</Typography>
                            <CarouselProvider {...defaultCarouselProps} totalSlides={slides.length} sx={carouselProviderSX}>
                                <Slider >
                                    {slides.map((slide: JSX.Element, i: number) => (
                                        <CarouselSlide
                                            index={i}
                                            key={slide.key}
                                            sx={carouselSlideTestimonialSX}
                                        >
                                            {slide}
                                        </CarouselSlide>
                                    ))}
                                </Slider>
                                {!smMatch ? <> <ButtonBack>
                                    <ArrowBackIosRoundedIcon />
                                </ButtonBack>
                                    <ButtonNext>
                                        <ArrowForwardIosRoundedIcon />
                                    </ButtonNext> </> : null}
                                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={dotContainerSX}>
                                    {slides.map((slide: JSX.Element, index: number) => index % visibleSlides === 0 ? <Dot slide={index} key={index} style={{ margin: '15px' }} /> : null)}
                                </Grid>
                            </CarouselProvider>
                        </>
                    )}
                </>) : null
            }
        </>
    );
};
