/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const carouselSlideTestimonialSX: SxProps<Theme> = (theme) => ({
    '&.carousel__slide': {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    '.carousel__inner-slide': {
        position: 'relative',
        margin: '0 10px',
        [theme.breakpoints.down(410)]: {
            marginLeft: '0px'
        },
        pb: '2px',
        display: 'flex',
        flexDirection: 'column',
        '.MuiCard-root': {
            flexGrow: 1,
        },
    },
});
